import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Version } from './versionning.types';

@Injectable({
    providedIn: 'root',
})
export class VersiongService {
    private _version_date: BehaviorSubject<Version> = new BehaviorSubject<Version>(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */

    get version$(): Observable<Version> {
        return this._version_date.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    getVersion(): Observable<Version> {
        if (this._version_date.value == null) {
            return this._httpClient
                .get<Version>(environment.server + 'api/version')
                .pipe(
                    tap((v) => {
                        console.log("Response from: " + environment.server + 'api/version/build-date');
                        this._version_date.next(v);
                    })
                );

        }
        else {
            return of(this._version_date.value)
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class VersionningResolver implements Resolve<string>
{
    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private _versionningService: VersiongService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        console.log("resolving version");
        return this._versionningService.getVersion();
    }
}
