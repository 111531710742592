import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { isLoading, LoaderHttpInterceptor } from './modules/interceptors/loader-http.interceptor';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, AfterContentChecked, OnDestroy {
    loading = false
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    /**
     * Constructor
     */
    constructor(private changeDetectorRef: ChangeDetectorRef) {

    }
    ngAfterContentChecked(): void {
        //this.changeDetectorRef.detectChanges()
    }

    ngAfterViewInit(): void {
        isLoading.pipe(takeUntil(this._unsubscribeAll)).subscribe(v => {
            const changed = this.loading != v
            this.loading = v;
            if (changed) {
                this.changeDetectorRef.detectChanges(); /**/

            }
        })
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    /*ngOnInit(){
        console.log("app-init");
        Office.onReady(function () {
            console.log("Excel ready");
        });
    }*/
}
